/* className located in the App.js file */
.portfolio {
  text-align: center;
}

/* found in the Navbar page */
.header {
  background-color: white;
  font-family: Arial, Helvetica, sans-serif;
  padding: 1rem;
}

/* .navLink {
  color: #030A8C;
} */

/* created className for about and aquatics pages */
.aboutAquatics {
  min-height: 95vh;
  color: #020873;
}


/* className for the logo pic on the Aquatics page */
.profile {
  margin-top: 25px;
}

/* found in the About page and Aquatics page */
.paragraph {
  padding-left: 10%;
  padding-right: 10%;
  margin-top: 30px;
  margin-left: 25px;
  margin-right: 25px;
  font-size: 150%;
  font-family: Arial, Helvetica, sans-serif;
  text-align: justify;
}

.instructor {
  justify-content: space-evenly;
}

/* this group is for the projects page */
.tech {
  padding: 15px;
}

.title {
  font-family: Arial, Helvetica, sans-serif;
  margin-top: 5px;
  color: #020873;
}

.ProjectCard1 {
  margin-top: 3rem;
}

.ProjectTitle {
  color: red;
  font-family: bookman;
}


