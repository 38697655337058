@import url(https://fonts.googleapis.com/css2?family=Eagle+Lake&display=swap);
/* className located in the App.js file */
.portfolio {
  text-align: center;
}

/* found in the Navbar page */
.header {
  background-color: white;
  font-family: Arial, Helvetica, sans-serif;
  padding: 1rem;
}

/* .navLink {
  color: #030A8C;
} */

/* created className for about and aquatics pages */
.aboutAquatics {
  min-height: 95vh;
  color: #020873;
}


/* className for the logo pic on the Aquatics page */
.profile {
  margin-top: 25px;
}

/* found in the About page and Aquatics page */
.paragraph {
  padding-left: 10%;
  padding-right: 10%;
  margin-top: 30px;
  margin-left: 25px;
  margin-right: 25px;
  font-size: 150%;
  font-family: Arial, Helvetica, sans-serif;
  text-align: justify;
}

.instructor {
  justify-content: space-evenly;
}

/* this group is for the projects page */
.tech {
  padding: 15px;
}

.title {
  font-family: Arial, Helvetica, sans-serif;
  margin-top: 5px;
  color: #020873;
}

.ProjectCard1 {
  margin-top: 3rem;
}

.ProjectTitle {
  color: red;
  font-family: bookman;
}



div.drop-container {
    position: relative;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    height: 200px;
    width: 200px;
  }
  
  
  div.drop {
    position: relative;
    top: -25%;
    width: 100%;
    height: 100%;
    border-radius: 100% 5% 100% 100%;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    margin: 0px;
    background: deepskyblue;
    -webkit-animation: drip 4s forwards;
            animation: drip 4s forwards;
  }
  
  h1 {
    color: white;
    position: absolute;
    font-size: 1em;
    height: 1em;
    top: 0; left: 0; right: 0; bottom: 0;
    z-index: 2;
    margin: auto;
    text-align: center;
    opacity: 0;
    -webkit-animation: appear 2s 2.5s forwards;
            animation: appear 2s 2.5s forwards;
  }
  
  @-webkit-keyframes appear {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @keyframes appear {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  div.drop-container:before,
  div.drop-container:after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 55%;
    right: 50%;
    -webkit-transform: translate(50%) rotateX(75deg);
            transform: translate(50%) rotateX(75deg);
    border-radius: 100%;
    opacity: 0;
    width: 75%;
    height: 75%;
    border: 5px solid skyblue;
    -webkit-animation: dripple 2s ease-out 1s;
            animation: dripple 2s ease-out 1s;
  }
  
  div.drop-container:after {
    -webkit-animation: dripple 2s ease-out 1.7s;
            animation: dripple 2s ease-out 1.7s;
  }
  
  @-webkit-keyframes drip {
    45% {
      top: 0;
      border-radius: 100% 5% 100% 100%;
      -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg);
    }
    100% {
      top: 0;
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
      border-radius: 100%;
    }
  }
  
  @keyframes drip {
    45% {
      top: 0;
      border-radius: 100% 5% 100% 100%;
      -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg);
    }
    100% {
      top: 0;
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
      border-radius: 100%;
    }
  }
  
  @-webkit-keyframes dripple {
    0% {
      width: 150px;
      height: 150px;
    }
    25% {
      opacity: 1;
    }
    100% {
      width: 500px;
      height: 500px;
      top: -20%;
      opacity: 0;
    }
  }
  
  @keyframes dripple {
    0% {
      width: 150px;
      height: 150px;
    }
    25% {
      opacity: 1;
    }
    100% {
      width: 500px;
      height: 500px;
      top: -20%;
      opacity: 0;
    }
  }

  /* remaining classes are for the contact page */
.contact {
  min-height: 90vh;
}

.clickToEmail {
  margin-top: 25px;
  margin-bottom: 15px;
}

.emailInfo {
  margin-top: 25px;
  margin-bottom: 15px;
}

.phoneInfo {
  margin-top: 25px;
  margin-bottom: 15px;
}
.home {
    min-height: 95vh;
    margin-top: 200px;
    /* color: #020873;
    font-size: 5em; 
    font-family: 'Eagle Lake'; */
  } 

  /* body{
    background-color:white;
  } */
  
  .loader{
    position:relative;
    top:50%;
    left:50%;
    -webkit-transform:translate(-50%,-50%);
            transform:translate(-50%,-50%);
    font-family:arial;
      
  }
  .loader h2{
    -webkit-text-decoration:uppercase;
            text-decoration:uppercase;
    /* font-size:8em; */
    font-family: 'Eagle Lake';
    color:rgba(0,0,0,.1);
    background-image:url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRhT9AXpUqUyMjILJ9qn9PDvXpdXE-iqbc-HMXHfU04s6rJ0fbT);
    background-repeat:repeat-x;
    -webkit-background-clip:text;
    -webkit-animation: animate 60s linear infinite;
            animation: animate 60s linear infinite;
    
  }
  
  @-webkit-keyframes animate{
    0%{
      background-position:left 0px top 80px;
    }
       40%{
      background-position:left 800px top -50px;
    }
    
      80%{
      background-position:left 400px top -50px;
    }

  }
  
  @keyframes animate{
    0%{
      background-position:left 0px top 80px;
    }
       40%{
      background-position:left 800px top -50px;
    }
    
      80%{
      background-position:left 400px top -50px;
    }

  }


  @media screen and (min-width: 100px) {
    h2 {
      font-size: 2em;
    }
  }

  @media screen and (min-width: 600px) {
    h2 {
      font-size: 4em;
    }
  }

  @media screen and (min-width: 800px) {
    h2 {
      font-size: 5em;
    }
  }

  @media screen and (min-width: 1000px) {
    h2 {
      font-size: 6em;
    }
  }

  @media screen and (min-width: 1200px) {
    h2 {
      font-size: 7em;
    }
  }

  @media screen and (min-width: 1400px) {
    h2 {
      font-size: 8em;
    }
  } 
